/*
*/

// import { databaseRef } from "../../config/firebase";
// import { database } from "../../config/firebase";
// import * as firebase from "firebase";
// import firebase from 'firebase/app';
import firebase from "firebase/compat/app";

import 'firebase/auth'

import * as actions_users from './actions_users';
import * as actions_projects from './actions_projects';


export const test_add_user = (event) => {
  console.log('AC test_add_user', event);

  let email = 'bob.geldoff@gmail.com';
  let password = 'password1'

  firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
    console.log('errorMessage', errorMessage)
  });

  return {
    type: "X"
  }; 
};

export const sign_up_user = (email, password, confirm_password) => {
  console.log('AC sign_up_user', email, password);

  if (!email){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No email entered!',
    })
  }

  if (!password){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No password entered!',
    })
  }
  if (!confirm_password){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No confirm password entered!',
    })
  }
  return dispatch => {
    // let email = 'bob.geldoff@gmail.com';
    // let password = 'password1'

    // firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
    //   // Handle Errors here.
    //   var errorCode = error.code;
    //   var errorMessage = error.message;
    //   // ...
    //   console.log('errorMessage', errorMessage)
    // });

    if (password !== confirm_password){
      console.log('PASSWORDS DID NOT MATCH');
      let auth_message = 'Passwords do not match!';
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:auth_message,
      })
      return {};
    }


    firebase.auth().createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
        //Handle error
        console.log('\n\ncreateUserWithEmailAndPassword -x-x-x-x-x-x-x-x-In Error');

        console.log('my errorMessage', error.message);
        let auth_message = '';
        if (error.code === 'auth/argument-error'){
          auth_message = 'Invalid entry!';
        }
        else if (error.code === 'auth/invalid-email'){
          auth_message = 'Invalid Email Address!';
        }
        else if (error.code === 'auth/wrong-password'){
          auth_message = 'Invalid Password!';
        }
        else {
          auth_message = error.message;
        }
        console.log(' auth_message ', auth_message);
        dispatch( { 
          type: "SET_AUTH_UI_MESSAGE", 
          message:auth_message,
        })


    })
    .then(function(user){
      if (!user){
        return {}
      }
      console.log('sign_up_user - uid',user.user.uid)
      console.log('sign_up_user - user',user)
      // try to send email vaification now
      user.user.sendEmailVerification()
      // create user
      dispatch(actions_users.create_new_user(user.user.uid, email ))
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:null,
      })
      dispatch(actions_projects.create_example_project(user.user.uid))


      //Here if you want you can sign in the user
    })
    // return {
    //   type: ""
    // }; 
  }
};

export const sign_in_user = (email, password) => {
  console.log('AC sign_in_user', email, password);

  // let email = 'bob.geldoff@gmail.com';
  // let password = 'password1'
  if (!email){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No email entered!',
    })
  }

  if (!password){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No password entered!',
    })
  }

  return dispatch => {

    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(function(result) {
      if (!result.user){
        return {}
      }
      console.log('\n\n sign in successful');
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:null,
      })
    })
    .catch(function(error) {
      // Handle Errors here.
      console.log('\n\n signInWithEmailAndPassword -x-x-x-x-x-x-x-x-In Error');
      console.log('my errorMessage', error.message);
      console.log('my error', error);

      let auth_message = '';
      if (error.code === 'auth/argument-error'){
        auth_message = 'Invalid entry!';
      }
      else if (error.code === 'auth/invalid-email'){
        auth_message = 'Invalid Email Address!';
      }
      else if (error.code === 'auth/user-not-found'){
        auth_message = 'User Not Found!';
      }
      else if (error.code === 'auth/wrong-password'){
        auth_message = 'Invalid Password!';
      }
      else {
        auth_message = error.message;
      }

      
      console.log(' auth_message ', auth_message);
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:auth_message,
      })
      // ...
    });
  }

  // return {
  //   type: "X"
  // }; 
};

export const sign_out_user = () => {
  console.log('AC sign_out_user', );

  let email = null;
  let password = null;

  firebase.auth().signOut().then(function() {
    console.log('Signed Out');
  }, function(error) {
    console.error('Sign Out Error', error);
  });

  return {
    type: "X"
  }; 
};


export const sign_in_user_modal_ok = (modal_data) => {
  console.log('AC sign_in_user_modal_ok', modal_data);

  return dispatch => {

    let email = modal_data.sign_in.email;
    let password = modal_data.sign_in.password;
    // dispatch( actions_projects.create_new_project( project_data, user_id, project_name))
    dispatch( sign_in_user(email, password))
    // dispatch( { 
    //     type: "CLOSE_MODAL_WINDOW", 
    //     modal_name:'sign_in',
    // })
    // dispatch( { 
    //     type: "ON_SELECT", 
    //     item_type: item_type,
    //     item_id: uid,
    // })
  }
};
export const sign_up_user_modal_ok = (modal_data) => {
  console.log('AC sign_up_user_modal_ok', modal_data);

  return dispatch => {

    let email = modal_data.sign_in.email;
    let password = modal_data.sign_in.password;
    let confirm_password = modal_data.sign_in.confirm_password;
    // dispatch( actions_projects.create_new_project( project_data, user_id, project_name))

    if (password !== confirm_password){
      console.log('PASSWORDS DID NOT MATCH');
      let auth_message = 'Passwords do not match!';
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:auth_message,
      })
      return {};
    }
    else{
      dispatch( sign_up_user(email, password))
      // dispatch( { 
      //     type: "CLOSE_MODAL_WINDOW", 
      //     modal_name:'sign_in',
      // })
      // dispatch( { 
      //     type: "ON_SELECT", 
      //     item_type: item_type,
      //     item_id: uid,
      // })
    }
  }
};
// export const sign_up_user_form = (email, password, confirm_password) => {
//   console.log('AC sign_up_user', email, password, confirm_password);

//   return dispatch => {

//     // let email = modal_data.sign_in.email;
//     // let password = modal_data.sign_in.password;
//     // let confirm_password = modal_data.sign_in.confirm_password;
//     // dispatch( actions_projects.create_new_project( project_data, user_id, project_name))

//     if (password !== confirm_password){
//       console.log('PASSWORDS DID NOT MATCH');
//       let auth_message = 'Passwords do not match!';
//       dispatch( { 
//         type: "SET_AUTH_UI_MESSAGE", 
//         message:auth_message,
//       })
//       return {};
//     }
//     else{
//       dispatch( sign_up_user(email, password))
//     }
//   }
// };


export const set_auth_message = (message) => {
  console.log('AC set_auth_message', message);
  return {
    type: "SET_AUTH_UI_MESSAGE", 
    message:message,
  }; 
};



export const reset_password = (email) => {
  console.log('AC reset_password', );

  if (!email){
    return( { 
      type: "SET_AUTH_UI_MESSAGE", 
      message:'No email entered!',
    })
  }

  return dispatch => {
    let auth_message = null;
    firebase.auth().sendPasswordResetEmail(email)
    .then(function() {
        console.log('password reset sent');
        auth_message = 'Please check your inbox for further instructions.';
        dispatch( { 
          type: "SET_AUTH_UI_MESSAGE", 
          message:auth_message,
        })
      })
      .catch(function(error) {
      //Handle error
      console.log('my errorMessage', error.message);
      // let auth_message = '';
      if (error.code === 'auth/argument-error'){
        auth_message = 'Invalid entry!';
      }
      else if (error.code === 'auth/invalid-email'){
        auth_message = 'Invalid Email Address!';
      }
      else if (error.code === 'auth/argument-error'){
        auth_message = 'Invalid Email Address!';
      }
      else if (error.code === 'auth/user-not-found'){
        auth_message = 'User not found!';
      }
      else {
        auth_message = error.message;
      }
      console.log(' auth_message ', auth_message);
      dispatch( { 
        type: "SET_AUTH_UI_MESSAGE", 
        message:auth_message,
      })
  })


    // }, function(error) {
    //   console.error('password reset Error', error);
    //   auth_message = 'Failed to reset password';
    // });


  }
};





// export const apply_for_beta = (user_id, email, data) => {
//   console.log('AC apply_for_beta', user_id, email, data);

//   if (!email){
//     return( { 
//       type: "SET_AUTH_UI_MESSAGE", 
//       message:'No email entered!',
//     })
//   }
  

//   return dispatch => {
//     // let uid = 'newProject_'+new_uid();
//     let db_path = Constants.DB_USER_DATA+'/'+user_id+'/beta/';
//     let beta_data = data

//     let date = new Date().toUTCString();
//     beta_data['application_date'] = 'xxxx'
//     beta_data['application_approved'] = false

//     console.log('beta_data', beta_data)
//     // database.ref(db_path).set(card_data)
//     actions_database.set_db_data(db_path, beta_data, window.SKIP_DATABASE)    
//     .then (
//         dispatch( { type: "SET_BETA_DATA", 
//             user_uid:user_id, 
//             user_data:user_data,
//         }),
//     )
//   }

// };