import React, { Component } from 'react';
import './CardThread.css';
import { connect} from 'react-redux';
import get_data_obj_from_string from '../../../../utils/get_data_obj_from_string'

import * as actionCreator_mouse from '../../../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../../../store/actions/actions_viewports';
import * as actionCreator_cards from '../../../../store/actions/actions_cards';
import * as actionCreator_cache from '../../../../store/actions/actions_cache';



class CardThread extends Component {

    constructor(props) {
        super(props);
        this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
    }

    handleOnMouseDown = (event) => {
        console.log('CardThread handleOnMouseDown', );
    
        this.props.onMouseDown(event, 'cardThread', this.props.connectionId);
        this.props.onMouseDown_VP(event, this.props.ui_data, this.props.viewport_data)
        this.props.onMouseDown_thread(event, this.props.threadId, this.props.connectionId, this.props.ui_data, this.props.thread_data, this.props.viewport_data);
        this.props.update_flow_viewport_cache();

    }
    
    render() {
        let outCardId =this.props.outCardId;
        let inCardId = this.props.inCardId;
        let threadColor = this.props.threadColor || 'red';

        let startPoint = this.props.outCardPos;
        let endPoint =  this.props.inCardPos;

        let dragging_extra_data = this.props.thread_data.cur_dragging[3] || null;


        if (this.props.outType === 'card'){
            startPoint = [this.props.outCardPos[0] + (.5 * this.props.out_card_size[0])-0, this.props.outCardPos[1] + 3];
        }

        if (this.props.inType === 'card'){
            // endPoint =  [this.props.inCardPos[0] + 315, this.props.inCardPos[1] + 16];
            // endPoint = [this.props.inCardPos[0] + (.05 * this.props.in_card_size[0]), this.props.outCardPos[1] - this.props.in_card_size[1]];
            endPoint = [this.props.inCardPos[0] + (.5 * this.props.in_card_size[0])-0, this.props.inCardPos[1] + 3];


        }
        // //AAA console.log('endPoint', outCardId)
        // //AAA console.log('startPoint', inCardId)
        // let lineTo = [endPoint[0] - startPoint[0], endPoint[1] - startPoint[1]]
        // //AAA console.log('lineTo', lineTo)
        // //AAA console.log('vpScale', this.props.vpScale)

        // let pathColor = this.props.pathColor || 'red';
        let pathColor = threadColor;
        let pathWidth = this.props.pathWidth || 1;
        pathWidth = 4 / pathWidth ;
        let selection_pathWidth = 10 ;

        let lineTo = [endPoint[0] - startPoint[0], endPoint[1] - startPoint[1]]

        // let is_hovering = false;
        let width_mult = 1;
        let path_style ={};
        let path_select_style = {};

        // this is a custom version of hovering style
        if (this.props.ui_data.control.hovering[0] === 'cardThread' && this.props.ui_data.control.hovering[1] === this.props.item_id){
            width_mult = 1.3;
            path_style = {
                opacity:.45,
            }
        }

        // this is to style thread which is being dragged
        if (this.props.thread_data.cur_dragging[0] === this.props.threadId && this.props.thread_data.cur_dragging[1] === this.props.connectionId){
            width_mult = 1.0;
            path_style = {
                opacity:.8,
            }
        }
        // this is to dissable thread selection when dragging thread
        if (this.props.thread_data.cur_dragging[0]){
            path_select_style = {'pointerEvents': 'none'}
        }

        if (dragging_extra_data){
            // console.log('XX dragging_extra_data', dragging_extra_data);
            // console.log('XX this.props.connectionId', this.props.connectionId);
            // console.log('XX this.props.threadId', this.props.threadId);

            if (dragging_extra_data.connection_ids.includes(this.props.connectionId) && dragging_extra_data.thread_id === this.props.threadId){
                // path_style['color'] = 'black';
                path_style['opacity'] = .8;
                pathColor = 'black';
            }
        }

        return (
            <svg 
                item_type='cardThread'
                item_id={this.props.item_id}
                className='SVG_BG' 
                height="10000"
                width="10000" 
                key={this.props.item_id}
            >
                {/* <g className='Pin' stroke="rgb(220, 0, 0)" strokeWidth="3" fill="rgb(220, 0, 0)">
                    <circle className='Pin' id="pointXB" cx={startPoint[0]} cy={startPoint[1]} r="4" />
                </g> */}

                <g className='Pin' fill="rgba(0, 0, 0, .06)">
                    {/* <circle id="pointA" cx={startPoint[0]-1} cy={startPoint[1]+3} r="12" /> */}
                    <circle className='Pin' id="pointB" cx={endPoint[0]-1} cy={endPoint[1]+3} r="10" />
                </g>

                <g className='Pin' fill="rgba(0, 0, 0, .06)">
                    {/* <circle id="pointA" cx={startPoint[0]-1} cy={startPoint[1]+3} r="12" /> */}
                    <circle className='Pin' id="pointBA" cx={startPoint[0]-1} cy={startPoint[1]+3} r="10" />
                </g>

                <path  
                    //item_type='cardThreadX'
                    //item_id={this.props.item_id}
                    //start_pos={[startPoint[0], startPoint[1]]}
                    //end_pos={[endPoint[0], endPoint[1]]}

                    start_x={startPoint[0]}
                    end_x={endPoint[0]}
                    start_y={startPoint[1]}
                    end_y={endPoint[1]}

                    className='Thread_Path' id="lineAB" 
                    d={'M ' + startPoint[0] + ' ' + startPoint[1] + ' l ' + lineTo[0] + ' ' + lineTo[1]} 
                    stroke={pathColor}
                    strokeWidth={width_mult * pathWidth} fill="none" 
                    style={path_style}
                    //onMouseDown={this.handleOnMouseDown}

                    //onMouseDown={this.props.onMouseDown_function.bind(this)}
                    //onMouseDown = {(e) => this.props.onMouseDown_function(e, this.props.thread_id, this.props.con_id, this.props.ui_data, this.props.thread_data )}
                />
                <path  
                    item_type='cardThread'
                    item_id={this.props.item_id}
                    //start_pos={[startPoint[0], startPoint[1]]}
                    //end_pos={[endPoint[0], endPoint[1]]}

                    start_x={startPoint[0]}
                    end_x={endPoint[0]}
                    start_y={startPoint[1]}
                    end_y={endPoint[1]}

                    className='Thread_Selector' id="lineAB" 
                    d={'M ' + startPoint[0] + ' ' + startPoint[1] + ' l ' + lineTo[0] + ' ' + lineTo[1]} 
                    stroke={pathColor}
                    strokeWidth={pathWidth+14} fill="none" 
                    //onMouseDown={this.props.onMouseDown_function.bind(this)}
                    onMouseDown={this.handleOnMouseDown}
                    style={path_select_style}
                    //onMouseDown = {(e) => this.props.onMouseDown_function(e, this.props.thread_id, this.props.con_id, this.props.ui_data, this.props.thread_data )}
                />

        
                <g className='Pin' stroke="rgb(220, 20, 20)" strokeWidth="3" fill="rgb(220, 20, 20)">
                    {/* <circle id="pointA" cx={startPoint[0]+1} cy={startPoint[1]-2} r="10" /> */}
                    <circle className='Pin' id="pointB" cx={startPoint[0]+1} cy={startPoint[1]-2} r="8" />
                </g>

                <g className='Pin' stroke="rgb(255, 30, 30)" strokeWidth="3" fill="rgb(250, 30, 30)">
                    {/* <circle id="pointA" cx={startPoint[0]+4} cy={startPoint[1]-7} r="7" /> */}
                    <circle className='Pin' id="pointB" cx={startPoint[0]+4} cy={startPoint[1]-7} r="5" />
                </g>

                <g className='Pin' stroke="rgb(220, 20, 20)" strokeWidth="3" fill="rgb(220, 20, 20)">
                    {/* <circle id="pointA" cx={startPoint[0]+1} cy={startPoint[1]-2} r="10" /> */}
                    <circle className='Pin' id="pointB" cx={endPoint[0]+1} cy={endPoint[1]-2} r="8" />
                </g>

                <g className='Pin' stroke="rgb(255, 30, 30)" strokeWidth="3" fill="rgb(250, 30, 30)">
                    {/* <circle id="pointA" cx={startPoint[0]+4} cy={startPoint[1]-7} r="7" /> */}
                    <circle className='Pin' id="pointB" cx={endPoint[0]+4} cy={endPoint[1]-7} r="5" />
                </g>

            </svg>
        )
    }
}
const mapStateToProps = (state, props) => {
    let current_vp = state.ui_data.current.current_viewport;

    let outDataSource = '';
    let inDataSource = '';
    let outDataSource_size = '';
    let inDataSource_size = '';
    if (props.outType === 'card') {
        outDataSource = "card_data." + props.outId + ".pos";
        outDataSource_size = "card_data." + props.outId + ".size";
    }
    if (props.inType === 'card') {
        inDataSource = "card_data." + props.inId + ".pos";
        inDataSource_size = "card_data." + props.inId + ".size";

    }
    if (props.inType === 'mouse') {
        // inDataSource = ""+current_vp[0]+"_data."+current_vp[1]+".mouse_pos";
        inDataSource = "ui_data.control.local_current_pos";
    }
    if (props.outType === 'mouse') {
        // inDataSource = ""+current_vp[0]+"_data."+current_vp[1]+".mouse_pos";
        outDataSource = "ui_data.control.local_current_pos";
    }
    return {
        outCardPos:get_data_obj_from_string(state, outDataSource),
        inCardPos:get_data_obj_from_string(state, inDataSource),
        out_card_size:get_data_obj_from_string(state, outDataSource_size),
        in_card_size:get_data_obj_from_string(state, inDataSource_size),
        // local_current_pos:state.ui_data.control.local_current_pos,
        // vpScale:state.flow_viewport_data.topLevel.scale,
        ui_data:state.ui_data,
        thread_data:state.thread_data,
        viewport_data:state[current_vp[0]+'_data'][current_vp[1]],

    }
}

const mapDispatchToProps = (dispatch, ) => {
    return {
        // delete_thread: (threadId, connectionId ) => dispatch({type:'DELETE_THREAD', threadId:threadId, connectionId:connectionId}),
        onMouseDown: (event, item_type, item_id) => dispatch(actionCreator_mouse.mouse_down(event, item_type, item_id)),
        onMouseDown_VP: (event, ui_data, viewport_data) => dispatch(actionCreator_viewports.mouse_down_viewport(event, ui_data, viewport_data)),
        onMouseDown_thread: (event, thread_id, con_id, ui_data, thread_data, viewport_data) => dispatch(actionCreator_cards.mouse_down_thread(event, thread_id, con_id, ui_data, thread_data, viewport_data)),
        update_flow_viewport_cache: () => dispatch(actionCreator_cache.update_flow_viewport_cache()),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(CardThread);


