
/////////////////////// Cards Action Creators  /////////////////////////////
///////////////////////////////////////////////////////////////////////////////
import { database } from "../../config/firebase";
import * as Constants from '../../constants/constants';


import new_uid from '../../utils/new_uid';
// import get_distance from '../../utils/get_distance';
// import get_database_item_path  from '../../utils/database_utils';
import * as database_utils from '../../utils/database_utils'
import * as card_utils from '../../utils/card_utils'
import * as misc_utils from '../../utils/misc_utils'



import get_mouse_pos_in_viewport from '../../utils/get_mouse_pos_in_viewport'

// import * as actions_utility from './actions_utility';
// import template_card from '../templates/template_card';
// import template_flow_viewport from '../templates/template_flow_viewport';
import templates from '../templates/templates';

import * as actions_database from './actions_database';
import * as actions_projects from './actions_projects';
import * as actions_viewports from './actions_viewports';
import * as actions_cache from './actions_cache';
import * as actions_users from './actions_users';

// var SKIP_DATABASE = true;

/*
CAN I MAKE A MORE GENERIC function for creating card with more explicit inputs and wrap that with functions
like create_card_at_current_pos?
Also, then i could split out tests like if hoverring[0] === 'viewport' 

*/


export const create_card_at_centre_of_vp = (event,  project_id, ui_data, viewport_data) => {
    // console.log('hey, hey hey')
    // console.log('AC create_card_at_centre_of_vp', event,  project_id, ui_data, viewport_data)

    return dispatch => {
        let hovering = ui_data.control.hovering;
        let current_viewport = ui_data.current.current_viewport;
        let local_current_pos = ui_data.control.local_current_pos;

        // if (hovering[0] === 'viewport') {
            
            let default_card_dimensions = [294, 196]
            let item_parent = current_viewport[1];

            // what are these numbers? I assume they relate to the offset of the pin location to the 
            // top left corner

            let viewportPos = viewport_data[item_parent].pos;
            let orig_scale = viewport_data[item_parent].scale;
    
            // let pos = [local_current_pos[0] - 165, local_current_pos[1] - 45,] ;

            let centre_pos = [
                ui_data.layout.main_panel_left  +  (.5 * ( ui_data.layout.main_panel_right - ui_data.layout.main_panel_left)),
                ui_data.layout.main_panel_top  +  (.5 * ( ui_data.layout.main_panel_bottom - ui_data.layout.main_panel_top)),
            ];
            let vp_mouse_pos = get_mouse_pos_in_viewport(
                [ui_data.layout.main_panel_left, ui_data.layout.main_panel_top], 
                viewportPos,
                orig_scale,
                centre_pos
                )

            let pos = [
                Math.round(vp_mouse_pos[0] - (.5*default_card_dimensions[0])),
                Math.round(vp_mouse_pos[1] - (.5*default_card_dimensions[1])),
            ]

            let uid = 'newCard_'+new_uid();

            // let db_path = Constants.DB_ROOT+'/card_data/'+uid;
            // NOTE: refactoring for project based DB structure
            // let db_path = Constants.DB_ROOT+'/project_data/'+ project_id + '/card_data/'+uid;
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'card', uid);

            //get card data from template and update pos and parent
            let card_data = {...templates['card']};
            card_data['pos']=pos;
            card_data['item_parent']=item_parent;
            card_data['creation_date'] = new Date().toUTCString();

            // console.log('card_data', card_data)
            // database.ref(db_path).set(card_data)
            actions_database.set_db_data(db_path, card_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "SET_CARD_DATA", 
                    uid:uid, 
                    data:card_data,
                }),
            )
            .then (
                dispatch( { 
                    type: "SELECT_ITEM_C", 
                    data: {[uid]:{item_type:'card'}},
                    select_mode: 'replace',
                })

            )
            // TODO: this set focus thing isn't working. Not sure why. I think its an async thing wherby the card is not made yet
            // by the time I try to get focus

            .then (
                dispatch( { 
                    type: "SET_INPUT_FOCUS", 
                    input_type:'textFieldCard',
                    input_id:'card_title_field'+uid,
                })
            )
            .then (
                dispatch( { 
                    type: "SET_FOCUS", 
                    element_id:'card_title_field'+uid,
                    // element_id:'card_title_fieldnewCard_09628793589552087',
                })

            )
            .then(
                dispatch(
                    actions_cache.generate_region_child_cache_b()
                )
            )

            // let input_to_focus = document.getElementById('card_title_field'+uid);
            
            // console.log('input_to_focus', input_to_focus);
            // if (input_to_focus){
            //     input_to_focus.focus();
            // }
            // document.getElementById('card_title_field'+uid).focus();

            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, 'card', [uid]))

        // }
        // else{
        //     return {type: ""};
        // }
    }


}

export const create_card_at_current_pos = (event,  project_id, ui_data) => {
    // console.log('hey, hey hey')
    // console.log('AC create_card_at_current_pos', event,  project_id, ui_data)

    return dispatch => {
        let hovering = ui_data.control.hovering;
        let current_viewport = ui_data.current.current_viewport;
        let local_current_pos = ui_data.control.local_current_pos;

        if (hovering[0] === 'viewport') {
            
 
            let item_parent = current_viewport[1];

            // what are these numbers? I assume they relate to the offset of the pin location to the 
            // top left corner
            let pos = [local_current_pos[0] - 165, local_current_pos[1] - 45,] ;

            let uid = 'newCard_'+new_uid();

            // let db_path = Constants.DB_ROOT+'/card_data/'+uid;

            // NOTE: refactoring for project based DB structure
            // let db_path = Constants.DB_ROOT+'/project_data/'+ project_id + '/card_data/'+uid;
            let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'card', uid);


            //get card data from template and update pos and parent
            let card_data = {...templates['card']};
            card_data['pos']=pos;
            card_data['item_parent']=item_parent;
            card_data['creation_date'] = new Date().toUTCString();

            // console.log('card_data', card_data)
            // database.ref(db_path).set(card_data)
            actions_database.set_db_data(db_path, card_data, window.SKIP_DATABASE)    
            .then (
                dispatch( { type: "SET_CARD_DATA", 
                    uid:uid, 
                    data:card_data,
                }),
            )
            .then (
                dispatch( { 
                    type: "SELECT_ITEM_C", 
                    data: {[uid]:{item_type:'card'}},
                    select_mode: 'replace',
                })

            )
            .then (
                dispatch( { 
                    type: "SET_INPUT_FOCUS", 
                    input_type:'textFieldCard',
                    input_id:'card_title_field'+uid,
                })
            )
            .then (
                dispatch( { 
                    type: "SET_FOCUS", 
                    element_id:'card_title_field'+uid,
                    // element_id:'card_title_fieldnewCard_09628793589552087',
                })
            )
            .then(
                dispatch(
                    actions_cache.generate_region_child_cache_b()
                )
            )
            // .then(
            //     dispatch(
            //         actions_users.set_user_guide_b('double_click_for_new_card', true)
            //     )
            // )
            .then(
                dispatch(
                    actions_users.set_user_guide_b('trigger_new_card_made', {element_id:uid})
                )
            )
            // link new item to project
            // dispatch(actions_projects.link_to_project(project_object, project_id, 'card', [uid]))

        }
        else{
            return {type: ""};
        }
    }


}
// export const create_card_at_current_pos = (event, ui_data) => {
//     //AAA console.log('AC create_card_at_current_pos', event, ui_data)


//     let hovering = ui_data.control.hovering;
//     let current_viewport = ui_data.current.current_viewport;
//     let local_current_pos = ui_data.control.local_current_pos;

//     if (hovering[0] === 'viewport') {
//         let item_parent = current_viewport[1];
//         let pos = [local_current_pos[0] - 165, local_current_pos[1] - 45,] ;

//         let uid = 'newCard_'+new_uid();

//         return { type: "SET_CARD_DATA", 
//             new_uid:uid, 
//             pos:pos,
//             item_parent:item_parent,
//         }
//     }
//     else{
//         return {type: ""};
//     }

// }




export const mouse_down_card = (event, card_item_data) => {
    // for when mouse is down on card - store orig position

    //AAA console.log('AC mouse_down_card', card_item_data)

    event.stopPropagation();
        
    let orig_pos = card_item_data['pos']

    return { type: "CARD_MOUSE_DOWN", 
        pos:orig_pos,
    }

}
export const mouse_down_anchor_OLD = (event, item_id, col_id) => {
    // for when mouse is down on card - store orig position

    //AAA console.log('AC mouse_down_card', item_id, col_id)

    event.stopPropagation();

    let uid = 'con_'+new_uid();   
    return { type: "NEW_THREAD", 
        new_uid:uid,
        col_id:col_id,
        item_id:item_id,
    }
}


// I have no idea if adding set clicked here is helpful or not
export const mouse_down_anchor = (event, item_id, col_id) => {
    // for when mouse is down on card - store orig position


    return dispatch => {
        event.stopPropagation();
        let uid = 'con_'+new_uid();   

        dispatch({  
            type: "NEW_THREAD", 
            new_uid:uid,
            col_id:col_id,
            item_id:item_id,
        })

        let clicked_data = {
            [col_id + ' ' + uid]:{item_type:'thread_anchor', 
                custom_data:{
                    thread_id:col_id,
                    con_id:uid,
                    start_or_end:1,
                    pre_drag_cons:null,
                },
            }
        }

        dispatch({  
            type: "SET_CLICKED", 
            data:clicked_data,
        })
        // dispatch(
        //     actions_users.set_user_guide_b('thread', true)
        // )
        
    }

}




// export const mouse_drag_card = (event, ui_data) => {
//     // rework this so handle function can only run this if dragging[0] === 'cardAnchor')
//     console.log('XXXXXX AC mouse_drag_card', event, ui_data )
  
//     let dragging = ui_data.control.dragging;
//     let begin_drag = ui_data.control.begin_drag;
//     let local_drag_offset = ui_data.control.local_drag_offset;
//     let local_orig_pos = ui_data.control.local_orig_pos;

//     if (begin_drag) {
//         let pos = [
//             Math.round(local_orig_pos[0] +  local_drag_offset[0]), 
//             Math.round(local_orig_pos[1] +  local_drag_offset[1])
//         ]
//         return { type: "SET_CARD_POS", 
//             card_id: dragging[1],
//             pos:pos,
//         }
//     }
//     else{
//         return {type: "x"};
//     }
// }






export const delete_thread = (project_id, thread_id, connection_id) => {
    // console.log('AC delete_thread', project_id, thread_id, connection_id )
    return dispatch => {
        // event.stopPropagation();
        // let db_path = Constants.DB_ROOT+'/thread_data/'+thread_id+'/connections/'+connection_id
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'thread', thread_id) + '/connections/'+connection_id;

        actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "DELETE_THREAD", 
                thread_id:thread_id,
                connection_id:connection_id,
            }),
        )
    }
}


export const set_current_thread = (event, project_id, thread_id) => {
    // console.log('AC set_current_thread', thread_id)

    event.stopPropagation();

    return dispatch => {
        // event.stopPropagation();
        // let db_path = Constants.DB_ROOT+'/ui_data/current_thread_colour'
        let db_path = Constants.DB_ROOT+'/project_data/'+ project_id + '/'+'ui_data/current_thread_colour';

        actions_database.set_db_data(db_path, thread_id, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "SET_CURRENT_THREAD", 
                thread_id:thread_id,
            }),
        )
    }
}


export const mouse_down_thread =  (event, thread_id, con_id, ui_data, thread_data, viewport_data) => {
    // I need to get enough information to set dragging and thread dragging... maybe this is where i can refactor out thread dragging.
    // could get hairy when doing multi select but thats a problem for later


    // console.log('AC mouse_down_thread', thread_id, con_id, ui_data, thread_data, viewport_data)
    return dispatch => {
        event.stopPropagation();

  
        // this is pretty crap but i've put it here to ensure this happens before other calculations.
        // if i just rely on ui_data.control.local_down_pos, i can't guarantee it will be evaluated in time.

        let mouse_down_pos =  [event.clientX, event.clientY];
        let main_panel_left = ui_data.layout.main_panel_left;
        let main_panel_top = ui_data.layout.main_panel_top;
        let local_orig_pos = viewport_data.pos;
        let vp_scale = viewport_data.scale;
    
        let cur_vp_pos = get_mouse_pos_in_viewport(
            [main_panel_left, main_panel_top], 
            local_orig_pos,
            vp_scale,
            mouse_down_pos
            )

        // let start_pos = event.target.getAttribute('start_pos');
        // let end_pos = event.target.getAttribute('end_pos');

        let start_x = Number(event.target.getAttribute('start_x'));
        let end_x = Number(event.target.getAttribute('end_x'));

        let start_y = Number(event.target.getAttribute('start_y'));
        let end_y = Number(event.target.getAttribute('end_y'));

        // console.log('start_pos', [start_x, start_y])
        // console.log('end_pos', [end_x, end_y])
        // console.log('cur_vp_pos', cur_vp_pos)

        let dist_start = misc_utils.get_distance(cur_vp_pos, [start_x, start_y])
        let dist_end = misc_utils.get_distance(cur_vp_pos, [end_x, end_y])

        // console.log('dist_start', dist_start)
        // console.log('dist_end', dist_end)

        let start_or_end_index = 0;
        let start_or_end = 'start';
        if (dist_start > dist_end){
            start_or_end_index = 1;
            start_or_end = 'end';
        }

        let pre_drag_cons = thread_data[thread_id]['connections'][con_id];


        dispatch({ 
            type: "SET_THREAD_CUR_DRAGGING", 
            col_id:thread_id,
            connection_id:con_id,
            start_or_end:start_or_end,
        })

        // let clicked_data = {
        //     col_id:thread_id,
        //     connection_id:con_id,
        //     start_or_end:start_or_end,
        // };
        // let clicked_data = [
        //     thread_id,
        //     con_id,
        //     start_or_end,
        //     pre_drag_cons,
        // ];

        let clicked_data = {
            [thread_id + ' ' + con_id]:{item_type:'thread', 
                custom_data:{
                    thread_id:thread_id,
                    con_id:con_id,
                    start_or_end:start_or_end,
                    pre_drag_cons:pre_drag_cons,
                },
            }
        }

        dispatch({  
            type: "SET_CLICKED", 
            data:clicked_data,
        })
        

        
        // dispatch({ 
        //     type: "SET_THREAD_TARGET", 
        //     target_data:['mouse', thread_id],
        //     thread_colour_id:thread_id,
        //     thread_id:con_id,
        //     start_or_end_index:start_or_end_index,
        // })
        // generate cache of card connections
        // dispatch( cache_thread_connection_data(thread_data) )

        
    }
}





// TODO move this function out of cards and into VP actions
// TODO REFACTOR TO HANDLE dragging_b (multiple objects) and also manage threads and pos on parenting

export const mouse_up_flow_vp = (event, project_id, ui_data, viewport_data, card_data, thread_data) => {
    /*
    This triggers when mouse up in flow vp - not on card or anchor
    */
    // console.log('AC mouse_up_flow_vp',)
    // console.log('AC mouse_up_flow_vp viewport_data', viewport_data)

    return dispatch => {

        let dragging = ui_data.control.dragging;
        let hovering = ui_data.control.hovering;
        // let begin_drag = ui_data.control.begin_drag;
        // let local_drag_offset = ui_data.control.local_drag_offset;
        // let local_orig_pos = ui_data.control.local_orig_pos;
        let dragging_b = ui_data.control.dragging_b || [];
    

        // if something thread is dragging and is attached to mouse then delete thread - eg you haven't connected to anything so get rid of it
        let thread_dragging = thread_data.cur_dragging
        let dragging_extra_data = thread_dragging[3] || null;

        let start_or_end_index = 0;
        if (thread_dragging[2] == 'end'){
            start_or_end_index = 1;
        }

        // This will handle if I'm dragging thread
        // TODO Could i use dragging b to hold this data rather than getting thread dragging from thread data?
        if (thread_dragging[0]) {
            let startType = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]][0][0];
            let endType = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]][1][0];
            let thread_con_data = thread_data[thread_dragging[0]]['connections'][thread_dragging[1]];

            let disconnect_thread_id = null;
            let disconnect_connection_id = null;


            // if start or end type is mouse it means conection was not made to anything, so can delete
            if (startType === 'mouse' || endType === 'mouse') {
                disconnect_thread_id = thread_dragging[0];
                disconnect_connection_id = thread_dragging[1];
 
                let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'thread', disconnect_thread_id) + '/connections/'+disconnect_connection_id;
                actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
                .then (
                    dispatch( { 
                        type: "DELETE_THREAD", 
                        thread_id:disconnect_thread_id,
                        connection_id:disconnect_connection_id,
                    }),
                )
            }
            else{
                // if start or end is not mouse it must be connected so I'll push that to DB 
                let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'thread', thread_dragging[0]) + '/connections/'+thread_dragging[1];
                actions_database.set_db_data(db_path, thread_con_data, window.SKIP_DATABASE)   
                
                // if replacing a connection, now delete the old one.
                if (dragging_extra_data){
                    // console.log('XX mouse_up_flow_vp dragging_extra_data', dragging_extra_data);
                    if (dragging_extra_data.hasOwnProperty('action')){
                        if (dragging_extra_data.action === 'disconnect_thread'){
                            disconnect_thread_id = dragging_extra_data.thread_id;
                            // disconnect_connection_id = dragging_extra_data.connection_id;
                            let disconnect_connection_ids = dragging_extra_data.connection_ids;

                            for (let index = 0; index < disconnect_connection_ids.length; index++) {
                                disconnect_connection_id = disconnect_connection_ids[index];

                                let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'thread', disconnect_thread_id) + '/connections/'+disconnect_connection_id;
                                actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
                                .then (
                                    dispatch( { 
                                        type: "DELETE_THREAD", 
                                        thread_id:disconnect_thread_id,
                                        connection_id:disconnect_connection_id,
                                    }),
                                )
                            }
       
                        }
                    }
                }
            }


        }

        // Now that mouse is up and threads dropping is resolved, set thread dragging to null
        dispatch({ 
            type: "SET_THREAD_CUR_DRAGGING", 
            col_id:null,
            connection_id:null,
            start_or_end:'end',
        })

        // Drag and drop over card to set item parent
        // need loop here

        let do_vp_frame = false;
        let item_array_for_framing = [];
        let vp_to_frame = null;

        for (let index = 0; index < Object.keys(dragging_b).length; index++) {
            const dragging_id = Object.keys(dragging_b)[index];
            let dragging_type = dragging_b[dragging_id].item_type;


            if (dragging_type === 'card' && hovering[0] === 'card'){

                if (dragging_id !== hovering[1]){
                    do_vp_frame = true;
                    let new_parent = hovering[1];
                    let card_vp = 'card_'+new_parent


                    let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'card', dragging_id) + '/item_parent';

                    console.log('set_par_db')

                    actions_database.set_db_data(db_path, card_vp, window.SKIP_DATABASE)    
                    .then (
                        dispatch( { 
                            type: "SET_CARD_PARENT", 
                            card_id:dragging_id,
                            item_parent:card_vp,
                        }),
                    )

                    // IF VP doesn't exist, make one
                    if (viewport_data.hasOwnProperty(card_vp) === false){
                        dispatch(
                            actions_viewports.create_flow_viewport(project_id, card_vp)
                        )
                    }

                    console.log(' mouse_up_flow_vp dragging_b', dragging_b);
                    let dragging_card_ids = Object.keys(dragging_b);
                    // card_utils.parent_cards_test(dragging_card_ids, new_parent, card_data, thread_data)
                    let lists_of_connections_to_break = card_utils.find_connections_to_break ( dragging_card_ids, thread_data, null )
                    console.log(' list_of_connections_to_break', lists_of_connections_to_break);
                    for (let ii = 0; ii < Object.keys(lists_of_connections_to_break).length; ii++) {
                        const thread_id = Object.keys(lists_of_connections_to_break)[ii];
                        let con_list = lists_of_connections_to_break[thread_id]
                        for (let iii = 0; iii < con_list.length; iii++) {
                            dispatch( delete_thread_connection(project_id, thread_id,  con_list[iii]) )
                        }
                    }


                    let mouse_down_pos = dragging_b[dragging_id].custom_data.mouse_down_pos;
                    let cur_vp = ui_data.current.current_viewport[1];
                    console.log('cur_vp', cur_vp);
                    console.log('viewport_data[cur_vp]', viewport_data[cur_vp]);
                    let cur_vp_pos = viewport_data[cur_vp].pos;
                    let cur_vp_scale = viewport_data[cur_vp].scale;

                    let new_vp_pos = [0,0];
                    let new_vp_scale = 1;
                    if (viewport_data.hasOwnProperty(card_vp)){
                        new_vp_pos= viewport_data[card_vp].pos;
                        new_vp_scale= viewport_data[card_vp].scale;
                    }


                    let new_pos = [
                        Math.round(mouse_down_pos[0] + (cur_vp_pos[0]/cur_vp_scale) - (new_vp_pos[0]/cur_vp_scale)),
                        Math.round(mouse_down_pos[1] + (cur_vp_pos[1]/cur_vp_scale) - (new_vp_pos[1]/new_vp_scale)),
                    ]
                    console.log('XX mouse_down_pos', mouse_down_pos);
                    console.log('cur_vp_pos', cur_vp_pos);
                    console.log('new_vp_pos', new_vp_pos);
                    console.log('new_pos', new_pos);

                     dispatch({ type: "SET_VP_XFORM_POS", 
                            item_id:dragging_id, 
                            item_type:dragging_type, 
                            pos:new_pos,
                    })

                    let item_size = card_data[dragging_id].size
                    item_array_for_framing.push({pos:new_pos, size:item_size})
                    vp_to_frame = card_vp;

                    
 

                }
            }
            // for (let index = 0; index < Object.keys(dragging_b).length; index++) {
            //     const dragging_id = Object.keys(dragging_b)[index];
            //     let dragging_type = dragging_b[dragging_id].item_type;
    
        
            if (dragging_type === 'card' && hovering[0] === 'breadcrumb'){
                do_vp_frame = true;
                let card_vp = hovering[1];

                let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'card', dragging_id) + '/item_parent';
                
                actions_database.set_db_data(db_path, card_vp, window.SKIP_DATABASE)    
                .then (
                    dispatch( { 
                        type: "SET_CARD_PARENT", 
                        card_id:dragging_id,
                        item_parent:card_vp,
                    }),
                )

                let mouse_down_pos = dragging_b[dragging_id].custom_data.mouse_down_pos;
                let cur_vp = ui_data.current.current_viewport[1];
                let cur_vp_pos = viewport_data[cur_vp].pos;
                let cur_vp_scale = viewport_data[cur_vp].scale;

                let new_vp_pos = [0,0];
                let new_vp_scale = 1;
                if (viewport_data.hasOwnProperty(card_vp)){
                    new_vp_pos= viewport_data[card_vp].pos;
                    new_vp_scale= viewport_data[card_vp].scale;
                }

                let new_pos = [
                    Math.round(mouse_down_pos[0] + (cur_vp_pos[0]/cur_vp_scale) - (new_vp_pos[0]/cur_vp_scale)),
                    Math.round(mouse_down_pos[1] + (cur_vp_pos[1]/cur_vp_scale) - (new_vp_pos[1]/new_vp_scale)),
                ]

                dispatch({ type: "SET_VP_XFORM_POS", 
                    item_id:dragging_id, 
                    item_type:dragging_type, 
                    pos:new_pos,
                })

                let item_size = card_data[dragging_id].size
                item_array_for_framing.push({pos:new_pos, size:item_size})
                vp_to_frame = card_vp;

            }
        }

        // now frame vp to newly nested cards (this is just to improve UX)
        //build item list
        if (do_vp_frame){
            console.log('IN FRAME SECTION');
            // for (let index = 0; index < Object.keys(dragging_b).length; index++) {
            //     const dragging_id = Object.keys(dragging_b)[index];
            //     let dragging_type = dragging_b[dragging_id].item_type;
            //     if (dragging_type === 'card'){
            //         item_array.push(card_data[dragging_id])
            //     }
            // }
            // console.log('FRAME item_array', item_array);
            console.log('FRAME item_array', item_array_for_framing);

            // setTimeout(
            // dispatch( actions_viewports.fit_flow_viewport_to_items(item_array, ui_data, 'flow_viewport', card_vp) )
            dispatch( actions_viewports.fit_flow_viewport_to_items(item_array_for_framing, ui_data, 'flow_viewport', vp_to_frame) )
                // ,3000);
        }

        
        // generate cache of card connections
        // dispatch( cache_thread_connection_data(thread_data) )

    }
          
}

// export const cache_thread_connection_data = ( thread_data,  ) => {
//     console.log('AC cache_thread_connection_data', thread_data);

//     let thread_connection_data = card_utils.get_thread_connected_cards( thread_data, null )
//     return ({
//         type: "SET_CACHE_DATA", 
//         key:'thread_connection_data', 
//         data:thread_connection_data, 
//     })
// }






export const delete_thread_connection = (project_id, thread_id, connection_id) => {
    /*
        delete thread connection
    */
    console.log('AC delete_thread_connection', project_id, thread_id, connection_id);

    return dispatch => {
        let db_path = database_utils.get_database_item_path(Constants.DB_ROOT, project_id, 'thread', thread_id) + '/connections/'+connection_id;

        actions_database.remove_db_data(db_path, window.SKIP_DATABASE)    
        .then (
            dispatch( { 
                type: "DELETE_THREAD", 
                thread_id:thread_id,
                connection_id:connection_id,
            }),
        )

    }
}






export const mouse_drag_thread = (event, ui_data, cache_data, thread_data, card_data) => {
    // rework this so handle function can only run this if dragging[0] === 'cardAnchor')
    // console.log('AC mouse_drag_thread', event, ui_data, thread_data )

    return dispatch => {

        let hovering = ui_data.control.hovering;
        let dragging = ui_data.control.dragging;
        let begin_drag = ui_data.control.begin_drag;
        let clicked = ui_data.control.clicked;
        let thread_connection_data = cache_data.thread_connection_data;

        if (!begin_drag){
            return{type:'x'}
        }

        
        let thread_dragging = thread_data.cur_dragging

        let start_or_end_index = 0;
        if (thread_dragging[2] == 'end'){
            start_or_end_index = 1;
        }

        // let do_set_thread_target = false;

        // get connected cards to thread
        // let cons = thread_data[thread_dragging[0]].connections[dragging[1]]
        let thread_id = thread_dragging[0];
        if (dragging[0] === 'cardAnchor' || dragging[0] === 'cardThread') {

            let test_thread_connection = card_utils.test_thread_connection(thread_id,  dragging, hovering, clicked, thread_data, card_data, thread_connection_data);
            let do_set_thread_target = false;

            // if (test_thread_connection){
                do_set_thread_target = test_thread_connection[0];
                // if (test_thread_connection[1]){
                    dispatch({ 
                        type: "SET_THREAD_CUR_DRAGGING", 
                        col_id:thread_dragging[0],
                        connection_id:thread_dragging[1],
                        start_or_end:thread_dragging[2],
                        extra_data:test_thread_connection[1],
                    })
                // }

            // }



            if (do_set_thread_target){
                dispatch({ type: "SET_THREAD_TARGET", 
                    target_data:['card', hovering[1]],
                    thread_colour_id:thread_dragging[0],
                    thread_id:thread_dragging[1],
                    start_or_end_index:start_or_end_index,
                })
            }
            else{
                dispatch({ type: "SET_THREAD_TARGET", 
                    target_data:['mouse', null],
                    thread_colour_id:thread_dragging[0],
                    thread_id:thread_dragging[1],
                    start_or_end_index:start_or_end_index,
                })
            }
        }
    }

}
