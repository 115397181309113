
/*
action creator for setting cached data - this is constructed data derived from my primary data in firebase and redux

*/

import * as attribute_utils from '../../utils/attribute_utils'
import * as viewport_utils from '../../utils/viewport_utils'

export const update_flow_viewport_cache = () => {
    /*
        simple function to update cache on flow viewport. I imagine i might extend this to be more sophisticated.
        might end up feeding in item_types to filter what things need updating.
        for now its all pretty cheap so i might just trigger this in a few places such as vp mouse down and card mouse down and mouse up
    */
    // console.log('AC update_flow_viewport_cache')

    return dispatch => {
        dispatch({type:'CACHE_THREAD_CONNECTIONS'})
    }
};

export const set_cache_data = (key, data) => {
    /*
        update a dict of keyframes in a central place.
        This will be triggered by things like select.
    */
    console.log('AC set_cache_data')

    return dispatch => {
        dispatch({
            type:'SET_CACHE_DATA',
            key:key,
            data:data,
        
        })
    }
};
export const update_keyframes_cache = (items_and_ids, timeline) => {
    /*
        update a dict of keyframes in a central place.
        This will be triggered by things like select.
    */

    let key_data = attribute_utils.get_key_data_from_items(items_and_ids, timeline)
    // console.log('AC update_keyframes_cache - key_data', key_data)

    return dispatch => {
        dispatch({
            type:'SET_CACHE_DATA',
            key:'key_data',
            data:key_data,
        
        })    }
};



export const generate_region_child_cache_b = () => {

    /*
        loop through all regions as well as cards and build a dict
        with chilren of each region
        only include chidren on current parent VP
    */
    // console.log('generate_region_child_cache_b start')
    return (dispatch, getState) => {

        let card_data = getState().card_data;
        let region_data = getState().region_data;
        let location_data = getState().location_data;
        
        let region_child_data = {};
        for (let i = 0; i < Object.keys(card_data).length; i++) {
            const card_id = Object.keys(card_data)[i];
            const card_parent = card_data[card_id].item_parent;

            for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
                const region_id = Object.keys(region_data)[ii];
                const region_parent = region_data[region_id].item_parent;

                if (region_parent !== card_parent) {
                    continue
                }
                if (!region_child_data.hasOwnProperty(region_id)) {
                    region_child_data[region_id] = [];
                }
                // let region_ = region_id[card_id].pos;
                // let region_size = region_id[card_id].size;
                let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

                if (viewport_utils.is_item_in_bbox(card_data[card_id], region_bbox)) {
                    region_child_data[region_id].push([card_id, 'card', card_data[card_id].pos])
                }
            }
        }

        // do same thing for regions now - this could be improved by combining in same
        // loop as above, but wont worry about that for now
        for (let i = 0; i < Object.keys(region_data).length; i++) {
            const region_id_a = Object.keys(region_data)[i];
            const region_parent_a = region_data[region_id_a].item_parent;

            for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
                const region_id = Object.keys(region_data)[ii];
                const region_parent = region_data[region_id].item_parent;

                if (region_id === region_id_a) continue;
                if (region_parent !== region_parent_a) continue;

                if (!region_child_data.hasOwnProperty(region_id)) {
                    region_child_data[region_id] = [];
                }
                // let region_ = region_id[card_id].pos;
                // let region_size = region_id[card_id].size;
                let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

                if (viewport_utils.is_item_in_bbox(region_data[region_id_a], region_bbox)) {
                    region_child_data[region_id].push([region_id_a, 'region', region_data[region_id_a].pos])
                }
            }
        }
        // do same thing for locations now - this could be improved by combining in same
        // loop as above, but wont worry about that for now
        for (let i = 0; i < Object.keys(location_data).length; i++) {
            const location_id = Object.keys(location_data)[i];
            const location_parent = location_data[location_id].item_parent;

            for (let ii = 0; ii < Object.keys(region_data).length; ii++) {
                const region_id = Object.keys(region_data)[ii];
                const region_parent = region_data[region_id].item_parent;

                if (region_parent !== location_parent) {
                    continue
                }
                if (!region_child_data.hasOwnProperty(region_id)) {
                    region_child_data[region_id] = [];
                }
                // let region_ = region_id[location_id].pos;
                // let region_size = region_id[location_id].size;
                let region_bbox = viewport_utils.get_bbox_from_item(region_data[region_id])

                if (viewport_utils.is_item_in_bbox(location_data[location_id], region_bbox)) {
                    region_child_data[region_id].push([location_id, 'location', location_data[location_id].pos])
                }
            }
        }
        // console.log('generate_region_child_cache end', region_child_data)

        
        dispatch({
            type: 'SET_CACHE_DATA',
            key: 'region_child_data',
            data: region_child_data,

        })
    }

}
