import React, { Component } from 'react';
import { useSelector } from "react-redux";

import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import * as Constants from '../constants/constants';
// import SignInWindow from '../components/modal_panels/SignInWindow';


import App from '../components/app/App';
import About from './About';
import Home from './Home';
import LoginPageF from './LoginPageF';
import SignUpPageF from './SignUpPageF';
import ForgotPassword from './ForgotPassword';
import UserSettingsPage from './UserSettingsPage';
import TestingPage from './TestingPage';
import BetaFormPage from './BetaFormPage';
import ProtectedRoute from './ProtectedRoute';


// import { constants } from 'buffer';
import { AuthProvider } from '../contexts/AuthContext';



// class AppRoot extends Component {
  

function  AppRoot(){

    let is_beta_approved = useSelector( state => state.context_data.beta_approved)


    return (
        <div>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        {/* <PrivateRoute path='/app' element={<App />} /> */}
                        {/* <PrivateRoute path='/app' element={<App />} /> */}

                        {is_beta_approved
                            ?(                          
                                // <Route element={<ProtectedRoute user={user} />}>
                                    <Route path='/app' element={<App />} />

                                // </Route>
                            )
                            : <Route path='/app' element={<Home />} />
                        }

                        <Route path='/login' element={<LoginPageF />} />
                        <Route path='/signup' element={<SignUpPageF />} />
                        <Route path='/forgotPassword' element={<ForgotPassword />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/user' element={<UserSettingsPage />} />
                        <Route path='/testing' element={<TestingPage />} />
                        <Route path='/betaForm' element={<BetaFormPage />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </div>
        
    );
}



export default AppRoot;


