export const FirebaseConfig = {
    apiKey: "AIzaSyBiIK8l9LPAmRby3Sd2cczp50XAsz9Ih00",
    authDomain: "avi-firebase-project.firebaseapp.com",
    databaseURL: "https://avi-firebase-project.firebaseio.com",
    projectId: "avi-firebase-project",
    storageBucket: "avi-firebase-project.appspot.com",
    messagingSenderId: "650930383127",
    appId: "1:650930383127:web:79872bc239c1b262436f52"
  };


  export const DeploySwitchConfig = {
    dev_mode: false,
    dev_string: '',
    reCaptchaSiteKey:'6LfrM6ApAAAAAECzoitI2F8_L04KPWQzcld3ZJf5'
  };