
import React, { Component } from 'react';
import {Navigate} from 'react-router-dom';

import './LoginPage.css';
import { connect} from 'react-redux';

import firebase from "firebase/compat/app";


import InputField from '../components/ui_elements/inputs/InputField';
import Button from '../components/ui_elements/inputs/Button';


import * as actionCreator_ui from '../store/actions/actions_ui';
import * as actionCreator_auth from '../store/actions/actions_auth';
import bg_image from '../images/landing_page_bg_01.jpg';

// ...later

class LoginPage extends Component {

      
    constructor(props) {
        super(props);
        this.handleClickSignIn = this.handleClickSignIn.bind(this);
        this.handleClickSignUp = this.handleClickSignUp.bind(this);
        this.state={redirect: null}
    }

    handleChange = e => {
        this.setState({
         [e.target.id]:e.target.value
       })}

    handleClickSignIn(event) {
        // this.props.sign_in_user_modal_ok(this.props.modal_data)
        this.props.sign_in_user(this.state.email, this.state.password)
    }
    // handleClickSignUpModal(event) {
    //     this.props.open_sign_up_modal()
    //     this.props.set_auth_message(null)
    // }
    handleClickSignUp(event) {
        this.setState({redirect:'signup'})
    }

    render() {

        let cur_user = firebase.auth().currentUser;
        console.log('LoginPage -cur_user', cur_user)
    
        if (cur_user){
            return (
                <Navigate to="/app" />
            )
        }

        if (this.state.redirect === 'signup'){
            return (
                <Navigate to="/signup" />
            )
        }
        
        let auth_ui_message = this.props.ui_data.auth_ui_message || null;


        let auth_message_element = null;
        if (auth_ui_message) {
            auth_message_element = <p style={{color:"red"}}> {auth_ui_message} </p>;
        }

  


        return (
            <div className="LoginPageDiv">
                <img className="LoginPageImg" src={bg_image} alt="logo" />

                <div className='LoginTitle'>StoryCraft.</div>

                <form  className="LoginPageForm">
                    <div className='LoginHeading'> Log in to StoryCraft </div>

                    {auth_message_element}
                    {/* {'\nemail: '+thi
                     */}
                    {/* <input
                        type="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        // data-minlength="3"
                        // data-maxnlength="20"
                        // data-message="Please enter your email"
                        />
                    <input
                        type="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        data-minlength="3"
                        data-maxnlength="20"
                        data-message="Please enter your password"
                        />
                    <input
                        type="password"
                        id="passwordConfirm"
                        value={this.state.passwordConfirm}
                        onChange={this.handleChange}
                        data-minlength="3"
                        data-maxnlength="20"
                        data-message="Please confirm your password"
                        /> */}
                    <InputField 
                        // dataSource={"ui_data.modal_data.sign_in.email"}
                        // dataTarget={"ui_data.modal_data.sign_in.email"}
                        inputType='email' 
                        style={{display: "block"}} 
                        id='email' 
                        label='email' 
                        // className='EmailField'
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.email}
                        />

                    
                    <InputField 
                        // dataSource={"ui_data.modal_data.sign_in.password"}
                        // dataTarget={"ui_data.modal_data.sign_in.password"}
                        inputType='password' 
                        style={{display: "block"}} 
                        id='password' 
                        label='password'
                        // className='PwField'
                        do_set_db_data='false'
                        onChangeFunction={this.handleChange}
                        inputValue={this.state.password}
                        
                        
                        />
                    <Button 
                        onClickFunction={this.handleClickSignIn} 
                        
                        label='Sign In' 
                        style={{width:"140px", top:"20px", margin:'auto'}}
                        />
                    <div style={{display:'block', padding:'10px'}}>
                        <p className='LoginText'> {"Don't have an account?  "}</p>
                        <p  className='LoginLink'
                            onClick={this.handleClickSignUp}
                            >  Sign Up Now
                        </p >

                    </div>
                </form>
            </div>
        )
    };

};


const mapStateToProps = (state, props) => {
    return {
        // modal_input_data:state.ui_data.openModalWindow[props.modalName],
        // modal_data:state.ui_data.modal_data,
        ui_data:state.ui_data,
        // context_data:state.context_data,

        // character_data:state.character_data,
        // card_data:state.card_data,
        // prop_data:state.prop_data,
        // location_data:state.location_data,

        // picked_items:state.ui_data.control.picked_items,
        // project_data:state.project_data,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // sign_in_user_modal_ok: ( modal_data) => dispatch(actionCreator_auth.sign_in_user_modal_ok(modal_data)),
        sign_in_user: (email, password) => dispatch(actionCreator_auth.sign_in_user(email, password)),
        // open_sign_up_modal: () => dispatch(actionCreator_ui.open_modal_window('sign_up', true)),
        set_auth_message: (message) => dispatch(actionCreator_auth.set_auth_message(message)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps )(LoginPage);

