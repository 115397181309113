import React, { Component } from 'react';

import { connect} from 'react-redux';
import './App.css';
import BannerPanel from '../main_panels/BannerPanel';
import ProjectsPanel from '../main_panels/ProjectsPanel';
import TestingPanel from '../main_panels/TestingPanel';
import AttributePanel from '../main_panels/AttributePanel';
import ModePanel from '../main_panels/ModePanel';
import MainPanel from '../main_panels/MainPanel';
import CreateNewCharacter from '../modal_panels/CreateNewCharacter';
import LinkItemsWindow from '../modal_panels/LinkItemsWindow';
import ModalWindow from '../modal_panels/ModalWindow';
import ModalWindowGenerator from '../modal_panels/ModalWindowGenerator';
// import SignInWindow from '../modal_panels/SignInWindow';
// import SignUpWindow from '../modal_panels/SignUpWindow';

import FoldoutPanel from '../ui_elements/objects/foldout_panel/FoldoutPanel';
import FoldoutPanelGenerator from '../ui_elements/objects/foldout_panel/FoldoutPanelGenerator';
import Guide_popups from '../guides/Guide_popups';

import Spinner from '../ui_elements/objects/general/Spinner';

import * as Constants from '../../constants/constants';

// import TestForm from '../forms/TestForm';

// import * as utils from '../../utils/misc_utils'


import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_utility from '../../store/actions/actions_utility';
import * as actionCreator_mouse from '../../store/actions/actions_mouse';
import * as actionCreator_viewports from '../../store/actions/actions_viewports';
import * as actionCreator_timeline from '../../store/actions/actions_timelines';
import * as actionCreator_time_control from '../../store/actions/actions_time_control';
import * as actionCreator_time_control_keys from '../../store/actions/actions_time_control_keys';
import * as actionCreator_time_cards from '../../store/actions/actions_time_cards';
import * as actionCreator_time_events from '../../store/actions/actions_time_events';
import * as actionCreator_cards from '../../store/actions/actions_cards';
import * as actionCreator_documents from '../../store/actions/actions_documents';
import * as actionCreator_database from '../../store/actions/actions_database';
import * as actionCreator_users from '../../store/actions/actions_users';
import * as actionCreator_projects from '../../store/actions/actions_projects';
import * as actionCreator_regions from '../../store/actions/actions_regions';

import * as actionCreator_viewport_xforms from '../../store/actions/actions_viewport_xforms';
import * as actionCreator_undo from '../../store/actions/actions_undo';


// import * as firebase from "firebase";
// import firebase from "firebase";
import firebase from "firebase/compat/app";

// import { constants } from 'buffer';
// import { constants } from 'http2';


// var SKIP_DATABASE = true;
window.SKIP_DATABASE=false;//declaring global variable by window object  


class App extends Component {
  
  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleToucheMove = this.handleToucheMove.bind(this);
    this.handleFlowMouseOver = this.handleFlowMouseOver.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    // this.handleMouseDown = this.handleMouseDown.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    // this.onKeyDown = this.onKeyDown.bind(this);
    // this.onKeyUp = this.onKeyUp.bind(this);
    // this.on_auth_state_change = this.on_auth_state_change.bind(this);

    // this will update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateDimensions();

    // this can be used to attach an icon to the mouse, eg for selection box
    this.mouse_icon = null;
    // this.mouse_icon = 'fas fa-globe';

    this.state = {
      mouse_pos:[0, 0],
      keyboard_inputs:[],
      run_once:false,
      // isFocus:false,
    }
  }



  onKeyDown(e){

    var key = e.which || e.keyCode; // keyCode detection
    var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection
    var shift = e.shiftKey ? e.shiftKey : ((key === 16) ? true : false); // shift detection
    var alt = e.altKey ? e.altKey : ((key === 18) ? true : false); // alt detection

    if ( key === 86 && ctrl ) {
        console.log("----- Ctrl + V Pressed !");
        // this.props.set_hotkey_signal('Ctrl + V')

    } else if ( key === 67 && ctrl ) {
        console.log("----- Ctrl + C Pressed !");
        // this.props.set_hotkey_signal('Ctrl + C')
    }

    else if ( key === 90 && ctrl ) {
      if ( shift) {
        console.log("----- Ctrl + Shift Z Pressed !  REDO");
        // this.props.set_hotkey_signal('Ctrl + Shift Z')
        // this.props.do_undo_redo('redo', this.props.undo_data);
      }
      else{
        console.log("----- Ctrl + Z Pressed ! UNDO");
        // this.props.set_hotkey_signal('Ctrl + Z')
        // this.props.do_undo_redo('undo', this.props.undo_data);
      }
    }

}

  // componentWillUnmount() {
  //     document.removeEventListener('mouseup', this.handleMouseUp);

  // }
  
  componentDidMount() {
    if (!window.SKIP_DATABASE){
      // console.log('App componentDidMount')

      if (!this.state.run_once){
        // console.log('App  run_once - user_data', this.props.user_data)
  
        if (this.props.user_data && this.props.context_data){
          if (this.props.user_data.hasOwnProperty(this.props.context_data.user)){
            // console.log('AppXXX  this.props.context_data.user', this.props.context_data.user)
            // console.log('AppXXX  this.props.user_data', this.props.user_data)
  
            // this is hacky as shit. Shoudl be done with promises or await or something?
            this.setState({run_once:true})
            this.props.add_user_usage_date(this.props.context_data.user, this.props.user_data[this.props.context_data.user])
  
          }
        }
      }

    }

    window.addEventListener("resize", this.updateDimensions);

    // I tried replacing the onMouseUp event on App with this to detect mouse up when mouse is outside
    // browese window. It worked for that, but card selection broke for some reason
    // don't understand why. For now, I'm adding this to document as well as onMouseUp on the App div
    // document.addEventListener('mouseup', this.handleMouseUp);

    firebase.auth().onAuthStateChanged(

      (user) => {
          if (user) {
            // User is signed in.

            // if signed in, get user data from DB and push into store
            let db_path = Constants.DB_USER_DATA
            //console.log('Constants.DB_USER_DATA', db_path)
            this.props.load_user_data_from_db(user.uid, db_path)

          } else {
            // No user is signed in.
            //console.log('componentWillMount AAA HEY USER!!!! NOT signed in ', user)

            this.props.set_current_user(null, null)
            // this.props.clear_current_user()
            // update_user_state = false;
      
            // this will clear cur proj and take me back to proj page
            this.props.set_current_project(null)
            
            // this.props.open_sign_in_modal()

            

          }
      }
    )
  }

 
  updateDimensions() {
    this.props.setWindowDimensions(window.innerWidth, window.innerHeight)
  }


  foldoutPanelBuild() {
    if (this.props.ui_data.foldout_panel){
      let panel_name = this.props.ui_data.foldout_panel['panel_name']
      let element_id = this.props.ui_data.foldout_panel['element_id']
      let extra_data = this.props.ui_data.foldout_panel['extra_data']

      return <FoldoutPanelGenerator panel_name={panel_name} element_id={element_id} extra_data={extra_data} />
    }
  }



  handleToucheMove(event) {
    // console.log('handleToucheMove', event)
    let touch_pos = [event.touches[0].clientX, event.touches[0].clientY];
    // console.log('handleToucheMove POS', touch_pos)

        if (event.hasOwnProperty('touches')){
          // console.log('handleToucheMove found touches')

        }



  }

  handleMouseMove(event) {
    // event.preventDefault()

    let mouse_pos = [];
    if (event.hasOwnProperty('touches')){
      // console.log('handleToucheMove found touches')

      mouse_pos = [event.touches[0].clientX, event.touches[0].clientY];
    }
    else{
      mouse_pos = [event.clientX, event.clientY];
    }

    this.setState({mouse_pos:mouse_pos})
    //if (this.mouse_icon){
    // let mouse_icon_style = {
    //   position:'fixed',
    //   left:event.clientX + 'px',
    //   top:event.clientY + 'px',
    //   // left:'300px',
    //   // top:'300px',
    //   // width:'300px',
    //   // height:'300px',
    //   // fontSize:'50px',
    //   }
      
      // console.log('mouse_icon_style', mouse_icon_style)
      // this.mouse_icon = <i style={mouse_icon_style} className='testtt fas fa-users'></i>;
    //}



    // this should always run if anything is being dragged
    if (this.props.ui_data.control.dragging[0] !== null) { 
      this.props.onMouseDrag(event, this.props.ui_data);

      if (this.props.ui_data.settings.mode === 'flow' || this.props.ui_data.settings.mode === 'map'){
        this.props.mouse_drag_viewport(event, this.props.ui_data, this.props.viewport_data);
      }
      else if (this.props.ui_data.settings.mode === 'timeline'){
        this.props.mouse_drag_timeline(event, this.props.ui_data, this.props.timeline_data);
      }

  
      // console.log('\nOOO this.props.ui_data.control.dragging', this.props.ui_data.control.dragging);
      // console.log('\nOOO this.props.ui_data.control.dragging_b', this.props.ui_data.control.dragging_b);
      // then switch based on what is being dragged
      let dragging_item_type = this.props.ui_data.control.dragging[0];
      switch (dragging_item_type) {
          // case 'viewport':
          //   this.props.mouse_drag_viewport(event, this.props.ui_data, this.props.viewport_data);
          //   break;

          // case 'timeline':
          //   this.props.mouse_drag_timeline(event, this.props.ui_data, this.props.timeline_data);
          //   break;

          case 'timeline_cursor':
            this.props.onMouseMove_cursor(event, this.props.current_timeline);
            break;

          case  'handle':
            this.props.mouse_drag_attr_panel(event, this.props.ui_data);
            break;

          // case 'card':
          //   this.props.onMouseDrag_cards(event, this.props.ui_data);
          //   break; 
            
          // case 'region':
          //   this.props.mouse_move_vp_xform(event, this.props.ui_data);
          //   break; 

          case 'card':
          case 'card_scale':
          case 'region':
          case 'region_scale':
          case 'location':

            // new section for new dragging key
            if (this.props.ui_data.control.dragging_b !== null) { 
              this.props.mouse_move_vp_xform(event, this.props.ui_data);
              if (this.props.cache_data.hasOwnProperty('region_child_data')){
                if (!dragging_item_type.endsWith('_scale')) {
                  this.props.mouse_move_region_children(this.props.ui_data, this.props.region_data, this.props.cache_data.region_child_data);
                }
              }
            }
            break;

            
          case 'cardAnchor':
          case 'cardThread':
            this.props.onMouseDrag_thread(event, this.props.ui_data, this.props.cache_data, this.props.thread_data, this.props.card_data);
            break;

          case 'timeCard':
          case 'timelineCardStart':
          case 'timelineCardEnd':
            // this.props.onMouseMoveTimelineCards(this.props.dragging, this.props.hovering,  this.props.begin_drag, this.props.viewport_data);
            this.props.mouse_drag_time_card(event, this.props.ui_data, this.props.card_data);
            break;


          case 'timeControl':
            this.props.mouse_drag_time_control(event, this.props.ui_data, this.props.timeline_data);
            break;

          case 'timeControl_key':
            this.props.mouse_move_tc_keys(event, this.props.ui_data, this.props.timeline_data, this.props.cache_data);
            break;

          case 'timeCursor':
            this.props.mouse_drag_time_cursor(event, this.props.ui_data, this.props.timeline_data);
            break;

          case 'timeEvent':
            this.props.mouse_drag_time_event(event, this.props.ui_data, this.props.event_data[this.props.dragging[1]]);
            break;
      }
    }
  }


  handleFlowMouseOver(event) {
      // console.log('handleFlowMouseOver event.target', event.target)
      this.props.onMouseOver(event,);
  }



  // handleMouseDown(event) {

  //   let dragging_type = this.props.ui_data.control.dragging[0];
  //   if ( ['card', 'card_scale', 'region', 'region_scale', 'location'].includes(dragging_type)) {
  //     if (this.props.ui_data.control.dragging_b !== null) { 
  //       this.props.generate_region_child_cache(this.props.region_data, this.props.card_data, this.props.location_data);
  //     }
  //   }
  // }

  handleMouseUp(event) {

    // the order of this is important - need to run vp up before xforms or it will screw selections
    this.props.mouse_up_viewport(event, this.props.ui_data, this.props.card_data, this.props.region_data, this.props.location_data);
    this.props.mouse_up_tc_keys(event, this.props.ui_data, this.props.cache_data);
    // TODO: I need to rework dragging an moving, its gotten too messy
    // need to refactor for dragging_b and get rid of dragging 
    let dragging_type = this.props.ui_data.control.dragging[0];
    if ( ['card', 'card_scale', 'region', 'region_scale', 'location'].includes(dragging_type)) {
      if (this.props.ui_data.control.dragging_b !== null) { 
        this.props.mouse_up_vp_xform(event, this.props.ui_data);
        this.props.generate_region_child_cache(this.props.region_data, this.props.card_data, this.props.location_data);

        if (!dragging_type.endsWith('_scale')) {
          if (this.props.cache_data.hasOwnProperty('region_child_data')){
            this.props.mouse_up_region_children(this.props.ui_data, this.props.region_data, this.props.cache_data.region_child_data);
          }
        }
      }
    }

    if ( ['card', 'card_scale', 'timeCard', 'timelineCardStart', 'timelineCardEnd'].includes(dragging_type)) {
      if (this.props.dragging[1]) {
        if (!this.props.begin_drag){
          // this.props.select_item_b('card', this.props.dragging[1], this.props.dragging[2]);
          // this.props.select_item('card', this.props.dragging[1]);
          let linked_doc = null;
          if (this.props.card_data[this.props.dragging[1]].hasOwnProperty('links')){
              if (this.props.card_data[this.props.dragging[1]].links.hasOwnProperty('document')){
                  if (this.props.card_data[this.props.dragging[1]].links.document){
                      linked_doc = this.props.card_data[this.props.dragging[1]].links.document[0];
                  }
              }
          }
          this.props.set_current_document(linked_doc);
        };
      }
    }

    
    // console.log('APP handleMouseUp this.props.dragging', this.props.dragging);
    switch (this.props.ui_data.control.dragging[0]) {
      case 'card':
      // case 'timeCard':
      // case 'timelineCardStart':
      // case 'timelineCardEnd':  
        // console.log('APP switch1 this.props.dragging', this.props.dragging);

        // if (this.props.dragging[1]) {
        //   if (!this.props.begin_drag){
        //       // this.props.select_item_b('card', this.props.dragging[1], this.props.dragging[2]);
        //       // this.props.select_item('card', this.props.dragging[1]);
        //       let linked_doc = null;
        //       if (this.props.card_data[this.props.dragging[1]].hasOwnProperty('links')){
        //           if (this.props.card_data[this.props.dragging[1]].links.hasOwnProperty('document')){
        //               if (this.props.card_data[this.props.dragging[1]].links.document){
        //                   linked_doc = this.props.card_data[this.props.dragging[1]].links.document[0];
        //               }
        //           }
        //       }
        //       this.props.set_current_document(linked_doc);
        //   };
        // };
        break; 
        
      case 'timeCard':
      case 'timelineCardStart':
      case 'timelineCardEnd':
        // console.log('APP switch2 this.props.dragging', this.props.dragging);
          // if nt dragging then select. if dragging then update time in db (via mouse_up_time_card)
          if (!this.props.begin_drag){
            // console.log('XXXXXXX this.props.begin_drag', this.props.begin_drag);
              this.props.select_item('card', this.props.dragging[1]);
              // this.props.set_current_document(this.props.dragging[1]);
          }
          else{
            this.props.mouse_up_time_card(event, this.props.ui_data, this.props.card_data[this.props.dragging[1]]);
          }
        // };
        break; 

      case 'timeEvent':
        if (this.props.dragging[1]) {
          // if (!this.props.begin_drag){
              this.props.select_item('event', this.props.dragging[1]);
              this.props.mouse_up_time_event(event, this.props.ui_data, this.props.event_data[this.props.dragging[1]]);
          // };
        };
        break; 

      // case 'timeControl_key':
      //   if (this.props.dragging[1]) {
      //     console.log('here timeControl_key')
      //     // if (!this.props.begin_drag){
      //         // this.props.select_item('event', this.props.dragging[1]);
      //         // this.props.mouse_up_time_event(event, this.props.ui_data, this.props.event_data[this.props.dragging[1]]);
      //         this.props.mouse_up_tc_keys(event, this.props.ui_data);
      //         // mouse_up_tc_keys = (event, ui_data)
      //     // };
      //   };
      //   break; 
    }
    this.props.onMouseUp(event);
    

  }



  



  // onKeyDown(event){
  //   // console.log('onKeyDown - isFocus. WELL IS IT?', this.state.isFocus)

  //   // if (this.state.isFocus){
  //   //   console.log('onKeyDown - isFocus')
  //   //   return;
  //   // }
  //   let key_down = event.key;
  //   console.log('onKeyDown', key_down)
  //   // this.props.setModifierKey(key_down, true)
  //   // let cur_keys = this.props.ui_data.
  //   let cur_keys_array  = this.state.keyboard_inputs;
  //   console.log('keyboard_inputs scur_keys_arraytate', cur_keys_array);
  //   cur_keys_array.push(key_down)
  //   this.setState({keyboard_inputs:cur_keys_array})

  //   console.log('keyboard_inputs state', cur_keys_array);

  //   // only send signal if cur_keys_array is 1. eg, dont resend on held key
  //   if (cur_keys_array.length === 1){
  //     if (cur_keys_array[0] === 'f'){
  //         console.log('keyboard_inputs pushing this key!', cur_keys_array[0]);
  //         this.props.fit_flow_viewport(this.props.ui_data, this.props.card_data, this.props.region_data);

  //       this.props.set_hotkey_signal('f')
  //     }
  //   }
  //   if (cur_keys_array.length === 2){
  //     if (cur_keys_array.includes('Control') && cur_keys_array.includes('c')){
  //         console.log('keyboard_inputs pushing this key!', cur_keys_array[0]);
  //         this.props.fit_flow_viewport(this.props.ui_data, this.props.card_data, this.props.region_data);

  //       this.props.set_hotkey_signal('Control c')
  //     }
  //   }

  // }
  // onKeyUp(event){
  //     let key_down = event.key;
  //     // this.props.setModifierKey(key_down, false)
  //     console.log('onKeyUp', key_down)

  //     this.setState({keyboard_inputs:[]})
  //     console.log('keyboard_inputs state', this.state.keyboard_inputs);
  //     this.props.set_hotkey_signal(null)


  // }

  // console.log() 

  // componentDidMount() {
  //   const inputs = document.getElementsByTagName('input');
  //   for (let input of inputs) {
  //       input.addEventListener('focus', () => this.setState({isFocus: true}));
  //       input.addEventListener('blur', () => this.setState({isFocus: false}));
  //   }
  // }

  render() {

    // console.log('OOOOO hovering', this.props.hovering,)

    // let cur_user = firebase.auth().currentUser;
    
    /*
    if (!this.state.run_once){
      // console.log('App  run_once - user_data', this.props.user_data)

      if (this.props.user_data && this.props.context_data){
        if (this.props.user_data.hasOwnProperty(this.props.context_data.user)){
          // console.log('AppXXX  this.props.context_data.user', this.props.context_data.user)
          // console.log('AppXXX  this.props.user_data', this.props.user_data)

          // this is hacky as shit. Shoudl be done with promises or await or something?
          this.setState({run_once:true})
          this.props.add_user_usage_date(this.props.context_data.user, this.props.user_data[this.props.context_data.user])

        }
      }
    }
    */

    // found this only - it forces tab to indent rather than change focus.\
    // NOTE: I should take this out of here and build it into text area input
    var textareas = document.getElementsByTagName('textarea');
    var count = textareas.length;
    for(var i=0;i<count;i++){
        textareas[i].onkeydown = function(e){
            if(e.keyCode==9 || e.which==9){
                e.preventDefault();
                var s = this.selectionStart;
                this.value = this.value.substring(0,this.selectionStart) + "\t" + this.value.substring(this.selectionEnd);
                this.selectionEnd = s+1; 
            }
        }
    }
    


    
    // TO DO: not sure if this is a great implimentation. is it bad for to be constantly setting window property?
    // NOt convinced this example project idea is the best anyway
    if (!Constants.EXAMPLE_PROJ_WRITE_TO_DB)
    {
      if (this.props.ui_data.current.current_project !== null && this.props.ui_data.current.current_project.startsWith('example')){
          // console.log(' \n\nTHIS IS THE EXAMPLE PROJECT!\n\n');
          window.SKIP_DATABASE=true;//declaring global variable by window object  
      }
      else{
        // console.log(' \n\nTHIS IS NOT THE EXAMPLE PROJECT!\n\n');
        window.SKIP_DATABASE=false;//declaring global variable by window object  
      }
    }




    // //if (this.mouse_icon){
    //   let mouse_icon_style = {
    //     position:'absolute',
    //     // left:event.clientX + 'px',
    //     // top:event.clientY + 'px',
    //     left:'300px',
    //     top:'300px',
    //     width:'300px',
    //     height:'300px',
    //     fontSize:'50px',
    //     }
        
    //     console.log('mouse_icon_style', mouse_icon_style)
    //   this.mouse_icon = <i style={mouse_icon_style} className='testtt fas fa-users'></i>;
    //   //}

    // style cursor depending on whats happening - doing here so it doesn;t change depending on where pointer is
    let cursor_class_name = '';
    if (this.props.dragging[0] === 'timeControl_key' && this.props.begin_drag){
      cursor_class_name = 'App_cursor_e_resize';

    }

    return (
      <div className={"App "+ cursor_class_name}
        onMouseMove={this.handleMouseMove}
        onTouchMove={this.handleMouseMove}
        //onTouchMove={this.handleToucheMove}
        // onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onTouchEnd ={this.handleMouseUp}
        onMouseOver={this.handleFlowMouseOver}
        onKeyDown={this.onKeyDown}
        // onKeyUp={this.onKeyUp}
        
      >



        {this.props.ui_data.current.current_project !== null && 
            <MainPanel/>
        }
        {this.props.ui_data.current.current_project !== null && 
            <ModePanel/>
        }
        {this.props.ui_data.current.current_project !== null && 
            <AttributePanel/>        
        }
        {this.props.ui_data.current.current_project === null &&  
          <ProjectsPanel/>
        }
        {this.props.ui_data.settings.show_testing_panel === true &&  
          <TestingPanel/>
        }
        {this.props.is_loading === true &&  
          <div id='loading' className='LoadingScreen'>
            <Spinner/>
          </div> 
        }
        <BannerPanel/>

        <ModalWindowGenerator/>
        {this.foldoutPanelBuild()}
        <Guide_popups user_id={this.props.context_data.user}/>
                
        {this.mouse_icon !== null && 
          <i style={{left:this.state.mouse_pos[0], top:this.state.mouse_pos[1], }} className={'testtt ' + this.mouse_icon}></i>
        }
      </div>
      
      
      );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}



const mapStateToProps = (state) => {
  let current_vp = state.ui_data.current.current_viewport;
  let current_tl = state.ui_data.current.current_timeline;
  return {
    user_data:state.user_data,

    ui_data:state.ui_data,
    is_loading:state.ui_data.layout.is_loading,
    thread_data:state.thread_data,
    dragging:state.ui_data.control.dragging,
    hovering:state.ui_data.control.hovering,
    begin_drag:state.ui_data.control.begin_drag,
    viewport_data:state[current_vp[0]+'_data'][current_vp[1]],
    timeline_data:state['timeline_data'][current_tl],
    current_viewport:state.ui_data.current.current_viewport,
    current_timeline:state.ui_data.current.current_timeline,

    character_data:state.character_data,
    card_data:state.card_data,
    prop_data:state.prop_data,
    location_data:state.location_data,
    event_data:state.event_data,
    region_data:state.region_data,

    cache_data:state.cache_data,
    context_data:state.context_data,

    main_panel_right:state.ui_data.layout.main_panel_right,
    main_panel_bottom:state.ui_data.layout.main_panel_bottom,


  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMouseMoveTimelineCards: (dragging, hovering, begin_drag, viewport_data) => dispatch({type:'TIMELINE_CARD_MOUSE_MOVE', dragging:dragging, hovering:hovering, begin_drag:begin_drag, viewport_data:viewport_data}),
    onMouseMove_TL: (event, tl_id, ) => dispatch({type:'ON_MOUSE_MOVE_TS', event:event, tl_id:tl_id,}),
    onMouseMove_cursor: (event, tl_id, ) => dispatch({type:'ON_MOUSE_MOVE_TIMELINE_CURSOR', event:event, tl_id:tl_id,}),
    setWindowDimensions: (width, height) => dispatch({type:'SET_WINDOW_DIMENSIONS', width:width, height:height}),
    onMouseOver:(event) => dispatch(actionCreator_mouse.mouse_over(event)),
    onMouseDrag:(event, ui_data) => dispatch(actionCreator_mouse.mouse_drag(event, ui_data)),
    onMouseDrag_thread:(event, ui_data, cache_data, thread_data, card_data) => dispatch(actionCreator_cards.mouse_drag_thread(event, ui_data, cache_data, thread_data, card_data)),
    mouse_drag_viewport:(event, ui_data, vp_data) => dispatch(actionCreator_viewports.mouse_drag_viewport(event, ui_data, vp_data)),
    mouse_move_vp_xform:(event, ui_data,) => dispatch(actionCreator_viewport_xforms.mouse_move_vp_xform(event, ui_data,)),
    mouse_up_vp_xform:(event, ui_data,) => dispatch(actionCreator_viewport_xforms.mouse_up_vp_xform(event, ui_data,)),
    mouse_drag_timeline:(event, ui_data, tl_data) => dispatch(actionCreator_timeline.mouse_drag_timeline(event, ui_data, tl_data)),
    mouse_drag_time_card:(event, ui_data, card_data) => dispatch(actionCreator_time_cards.mouse_drag_time_card(event, ui_data, card_data)),
    mouse_drag_time_control:(event, ui_data, tl_data) => dispatch(actionCreator_time_control.mouse_drag_time_control(event, ui_data, tl_data)),
    mouse_move_tc_keys:(event, ui_data, tl_data, cache_data) => dispatch(actionCreator_time_control_keys.mouse_move_tc_keys(event, ui_data, tl_data, cache_data)),
    mouse_up_tc_keys:(event, ui_data, cache_data) => dispatch(actionCreator_time_control_keys.mouse_up_tc_keys(event, ui_data, cache_data)),
    mouse_drag_time_cursor:(event, ui_data, tl_data) => dispatch(actionCreator_timeline.mouse_drag_time_cursor(event, ui_data, tl_data)),
    mouse_drag_time_event:(event, ui_data, event_item_data) => dispatch(actionCreator_time_events.mouse_drag_time_event(event, ui_data, event_item_data)),
    mouse_up_time_card:(event, ui_data, card_item_data) => dispatch(actionCreator_time_cards.mouse_up_time_card(event, ui_data, card_item_data)),
    mouse_up_time_event:(event, ui_data, event_item_data) => dispatch(actionCreator_time_events.mouse_up_time_event(event, ui_data, event_item_data)),
    mouse_up_viewport:(event, ui_data, card_data, region_data, location_data) => dispatch(actionCreator_viewports.mouse_up_viewport(event, ui_data, card_data, region_data, location_data)),
    mouse_drag_attr_panel:(event, ui_data) => dispatch(actionCreator_mouse.mouse_drag_attr_panel(event, ui_data)),
    onMouseUp: (event) => dispatch(actionCreator_mouse.mouse_up(event)),
    select_item: (item_type, item_id) => dispatch(actionCreator_ui.select_item(item_type, item_id)),
    set_current_document: (doc_id) => dispatch(actionCreator_documents.set_current_document(doc_id)),
    load_user_data_from_db: (user_id, db_path) => dispatch(actionCreator_database.load_user_data_from_db(user_id, db_path)),
    set_current_user: (user_id, user_email) => dispatch(actionCreator_users.set_current_user(user_id, user_email)),    
    add_user_usage_date: (user_id, user) => dispatch(actionCreator_users.add_user_usage_date(user_id, user)),    
    set_current_project: ( project_id) => dispatch(actionCreator_projects.set_current_project( project_id)),
    set_hotkey_signal: (key) => dispatch(actionCreator_ui.set_hotkey_signal(key)),
    generate_region_child_cache: (region_data, card_data, location_data) => dispatch(actionCreator_regions.generate_region_child_cache(region_data, card_data, location_data)),
    mouse_move_region_children: (ui_data, region_data,region_child_cache) => dispatch(actionCreator_regions.mouse_move_region_children(ui_data, region_data, region_child_cache)),
    mouse_up_region_children: (ui_data, region_data, region_child_cache) => dispatch(actionCreator_regions.mouse_up_region_children(ui_data, region_data, region_child_cache)),
    do_undo_redo: (mode, undo_data) => dispatch(actionCreator_undo.do_undo_redo(mode, undo_data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps )(App);

