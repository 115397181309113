
import React, { Component } from 'react';
import './ModalWindow.css';
import { connect} from 'react-redux';
import InputField from '../ui_elements/inputs/InputField';
import Button from '../ui_elements/inputs/Button';

import CreateNewCharacter from './CreateNewCharacter';
import CreateNewProject from './CreateNewProject';
import CreateNewProp from './CreateNewProp';
import CreateNewLocation from './CreateNewLocation';
import CreateNewEvent from './CreateNewEvent';
import LinkItemsWindow from './LinkItemsWindow';
import AddAttributeWindow from './AddAttributeWindow';
import PickImage from './PickImage';
import UserSettings from './UserSettings';


import * as actionCreator_ui from '../../store/actions/actions_ui';
import * as actionCreator_story_items from '../../store/actions/actions_story_items';
import * as actionCreator_projects from '../../store/actions/actions_projects';
import * as actionCreator_inputs from '../../store/actions/actions_inputs';


class ModalWindow extends Component {

      
    constructor(props) {
        super(props);
        this.handleClickCancel = this.handleClickCancel.bind(this);

    }
    handleClickCancel(event) {
        // block cancel behaviour if uploading (i don't want access to app until upload is done)
        if (!this.props.is_uploading){
            this.props.onClick(event, "ui_data.openModalWindow."+this.props.modalName, null)
        }
    }


    render() {
        let createWinStyle = null;
        let createWinStyleBG = null;
        let child_ui = null;
        let project_id = this.props.ui_data.current.current_project;
        // let project_object = this.props.project_data[project_id];

        let extra_data = {
            // clicked:true,
            // event:event,
            user_id:this.props.context_data.user,
            modal_name:this.props.modalName,
            project_id:project_id,
            // project_object:project_object,
            modal_data:this.props.modal_data[this.props.modalName],
            modal_input_data:this.props.modal_input_data,
            cancel_function:this.props.onClick,
            // cancel_args:[event, "ui_data.openModalWindow."+this.props.modalName, null],
        };

        if (this.props.modal_input_data)  {

            createWinStyle = {
                display:'block',
                visibility:'visible',
                transform: 'rotate(0deg)  scale(' + 1 + ',' +  1 + ')',
            }
            createWinStyleBG = {
                visibility:'visible',
                opacity:1,
            }

            // configure child ui with args depending on modalName
            if (this.props.modalName === 'create_character')  {
                child_ui = <CreateNewCharacter extra_data={extra_data} item_type='character' />
            }
            if (this.props.modalName === 'create_prop')  {
                child_ui = <CreateNewProp  extra_data={extra_data} item_type='prop' />
                // createWinStyle['width'] = '50px';
            }
            if (this.props.modalName === 'create_location')  {
                child_ui = <CreateNewLocation extra_data={extra_data} item_type='location' />
            }
            if (this.props.modalName === 'create_event')  {
                child_ui = <CreateNewEvent extra_data={extra_data} item_type='event' />
            }
    
            if (this.props.modalName === 'link_items')  {
                child_ui = <LinkItemsWindow item_type='character' extra_data={extra_data} modal_input_data={this.props.modal_input_data} />
            }
            if (this.props.modalName === 'new_project')  {
                child_ui = <CreateNewProject  extra_data={extra_data} modal_input_data={this.props.modal_input_data} />
                createWinStyle['height'] = '230px';

            }
            if (this.props.modalName === 'pick_image')  {
                child_ui = <PickImage  extra_data={extra_data}  modal_input_data={this.props.modal_input_data} />
                createWinStyle['height'] = '325px';
                // createWinStyle['width'] = '600px';
            }
            if (this.props.modalName === 'add_attrs')  {
                child_ui = <AddAttributeWindow item_type='character' extra_data={extra_data} modal_input_data={this.props.modal_input_data} />
            } 
            if (this.props.modalName === 'user_settings')  {
                child_ui = <UserSettings item_type='user' extra_data={extra_data} modal_input_data={this.props.modal_input_data} />
            } 

        }
        else {
            createWinStyle = {
                visibility:'hidden',
                opacity:0,
                transform: 'rotate(0deg)  scale(' + .9 + ',' +  .9 + ')',
            }
            createWinStyleBG = {
                visibility:'hidden',
                opacity:0,

            }
        }

        // if uploading, then i'll hide cancel button
        let uploading_hide_style={};
        if (this.props.is_uploading){
            uploading_hide_style={display:'none'}
        }
        return (
            <div>
                <div style={createWinStyleBG}  className = "ModelWindowBG"
                    onClick={this.handleClickCancel}
                />
                <div style={createWinStyle} className="ModelWindow">
                    <div className='windowTitle'> {this.props.windowTitle} </div>
                    <Button 
                            dataTarget={"ui_data.openModalWindow."+this.props.modalName}
                            valToSet={null}
                            buttonType='closeButton' 
                            label='X' 
                            id='cancelButton' 
                            style={uploading_hide_style}
                            do_set_db_data='false'
                        />
                    <div className='customPanelDiv'>
                        {child_ui}
                    </div>
                </div>
            </div>
        )
    };

};


const mapStateToProps = (state, props) => {
    return {
        modal_input_data:state.ui_data.openModalWindow[props.modalName],
        modal_data:state.ui_data.modal_data,
        ui_data:state.ui_data,
        context_data:state.context_data,
        // project_data:state.project_data,
        is_uploading:state.ui_data.layout.is_uploading,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClick: (event, dataTarget, valToSet) => dispatch(actionCreator_inputs.on_click(event, dataTarget, valToSet, null, false)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps )(ModalWindow);

