
/*
Mouse (i guess touch eventually) related actions

*/

// import fullScreenToggle from '../../utils/fullScreenToggle'

// Banner actions

// export function full_screen_toggle() {
//     //AAA console.log('this is fullscreen action')
//     // do i need this to be structured like this?
//     return () =>  {
//         fullScreenToggle();
//     }
// }



// generic mouse down action
export const mouse_down = (event, item_type, item_id, ) => {
    // console.log('AC mouse_down', event, item_type, item_id, item )
    
    // let mousePos = [event.clientX, event.clientY];
    let mousePos = [];
    if (event.hasOwnProperty('touches')){
        mousePos = [event.touches[0].clientX, event.touches[0].clientY];
    }
    else{
        mousePos = [event.clientX, event.clientY];
    }
    return { type: "ON_MOUSE_DOWN", item_type:item_type, item_id:item_id, mousePos:mousePos };

};

export const mouse_over = (event,) => {
    // console.log('AC mouse_over', event,)
    
    let item_type = event.target.getAttribute('item_type')
    let item_id = event.target.getAttribute('item_id')
    // console.log('AC mouse_over', item_type, item_id)

    return { type: "ON_MOUSE_OVER", item_type:item_type, item_id:item_id};

};


export const mouse_up = (event) => {
    //AAA console.log('AC mouse_up', event,)

    // event.stopPropagation();

    return { type: "ON_MOUSE_UP",};
};




export const mouse_drag = (event, ui_data) => {
    // console.log('AC mouse_drag', event, ui_data)

    // test if any inputs are in focus - dont want to override dragging text highlighting
    if (ui_data.control.focus){
        // console.log('mouse_drag - skipping because an input is in focus ');
        return { type: "X", }
    }
    // can i make this function handle some of the logic i had in reducer?
    let mousePos = null;
    let mouseDownPos = null;
    let mouse_drag_offset = [0,0];

    // if dragging anything then store offset and set begin_drag if dragged far enough:
    // if (ui_data.control.dragging[0] !== null) { 
    // event.stopPropagation();

    if (event.hasOwnProperty('touches')){
        mousePos = [event.touches[0].clientX, event.touches[0].clientY];
    }
    else{
        mousePos = [event.clientX, event.clientY];
    }

    // mousePos = [event.clientX, event.clientY];
    mouseDownPos = ui_data.control.mouse_down_pos;
    mouse_drag_offset = [mousePos[0] - mouseDownPos[0], mousePos[1] - mouseDownPos[1]]


    // test if begin drag should kick in based on threshhold 
    let begin_drag = ui_data.control.begin_drag;
    let drag_threshold = 1;
    if (!begin_drag){

        if (mouse_drag_offset[0] > drag_threshold || mouse_drag_offset[0] < -drag_threshold || mouse_drag_offset[1] > drag_threshold || mouse_drag_offset[1] < -drag_threshold)
        {
            begin_drag = true;
        }
        
    }

    // these shoudl be actions for reducer
    // newState.ui_data.control.mouse_current_pos = mousePos;
    // newState.ui_data.control.mouse_drag_offset = mouse_drag_offset
    // newState.ui_data.control.begin_drag = begin_drag;

    return { type: "ON_MOUSE_DRAG", 
        mouse_current_pos:mousePos, 
        mouse_drag_offset:mouse_drag_offset,
        begin_drag:begin_drag,
    };


};


export const mouse_drag_attr_panel = (event, ui_data) => {
    //AAA console.log('AC mouse_move_attr_panel', event, ui_data)

    if (ui_data.control.dragging[0] === 'handle'){
        if (ui_data.control.dragging[1] === 'attribute_panel'){

            let mouse_drag_offset = ui_data.control.mouse_drag_offset;
            let drag_width = ui_data.layout.attribute_panel_width_mouse_down - mouse_drag_offset[0];
            let new_width = drag_width;
            let expanded_width = drag_width;
            // let attribute_panel_expanded = ui_data.settings.attribute_panel_expanded;
            let attribute_panel_expanded = true;

            if (drag_width < 280) {
                new_width =280;
                expanded_width =280;
                // attribute_panel_expanded = false;
            }
            
            // THIS SHOULD EVENTUALLY BE REPLCED BY SOMEHING AWARE OF CURRENT WINDOW SIZE
            if (drag_width > 1000) {
                new_width =1000;
                expanded_width =1000;

            }

            if (drag_width < 80) {
                new_width =0;
                expanded_width =280;
                attribute_panel_expanded = false;
            }
            
            return { type: "ON_DRAG_ATTR_PANEL_HANDLE", 
                attribute_panel_expanded:attribute_panel_expanded, 
                attribute_panel_width:new_width,
                attribute_panel_expanded_width:expanded_width,
                main_panel_right:ui_data.layout.window_dimensions[0] - new_width,
            };

        };
    }
}





